
*,

*::after { 
  box-sizing: border-box;
}

html {
  background-color: #f0f0f0;
}

body {
  color: black;
  font-family: 'Roboto','Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  padding: 1rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.btn {
  background-color: white;
  border: 1px solid gray-light;
  color: gray-dark;
  padding: 0.5rem;
  text-transform: lowercase;
}

.btn--block {
  display: block;
  width: 100%;
}
 
.cards {
  display: block;
  list-style: none;
  padding:15px  30px;
  width: auto;
  border: none;
  background-color: #e0e1e1;
}

.cards__item {
  margin-bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  @media(min-width: 600px) {
    width: 100%;
  }
}



.card:hover {
  border: solid yellow;
  border-width: 1.6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Hier können Sie die Werte anpassen */
}
@media only screen and (max-width: 600px) {
  .card {
    border: solid yellow;
    border-width: 1.6px;
    height: auto;
  }
  .card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Hier können Sie die Werte anpassen */
  }
  .card {
    padding: 0;
    margin: 0;
  }
  .card__image {
    display: flex;
    flex-direction: column;
    background-color: red;
  }
  .card__image  img {
    padding: 0;
    margin: 0;
    height: 20rem;
  }
  .card__content {
    
    background-color: rgb(230, 255, 247);
  }


  .distance {
    margin-left: 1rem;
  }

  .see-more {
    margin-top: 0.2rem;
    font-style: italic;
    align-self: flex-end; 
  }
  .card__text {
    font-size: 1rem;
    line-height: 1.4;
  }

}
.card__content {
  padding: 0.5rem 1rem ;
}

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  filter: contrast(90%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__image img {
  height: 100%;
  width: auto;
}

.card__title{
  color: gray-dark;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card__text {
  font-size: 1rem;
  line-height: 1.5;
}
.model-name{
  font-size: 1.2rem;
  font-weight: 800;
  color: green;
  margin-bottom: 0.3rem;

}
.prices a{
  font-size: 1rem;
  background-color: #F8DC75;
  
}
.distance {
  margin-left: 1rem;
}
.see-more {
  margin-top: 0.2rem;
  font-style: italic;
}