.modal-and-slider-container{
  border-radius: 7px;
  max-width: 2rem;
  max-height: 2rem;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.modal-content {
  display: block;
  background: #fff; /* White background for the modal content */
  border-radius: 8px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: none;
  border-radius: 5px;
  border-radius: 10px;
}
.slider-pics {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 37rem;
}
.img-container{
  height: 80%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
}

.img-container img {
  height: 100%;
  width: auto;
  max-width: 100%; 
  max-height: 100%; 
  border-radius: 5px;
 
}
.img-decription {
  margin-top: 0;
  height:100%;
  margin: 0;
  padding: 0;
  padding: 0.2rem 3rem;
  background-color: #F0F04A;
  line-height: 1.6rem;
  font-size: 1rem;
}

.slider-pics img {
   height: 100%;
   width: auto;
}

.arrows {
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  border: none;
  border-radius: 4px;
  width: 3rem;
  height: 10.5rem;
  background-color: transparent;
  margin-bottom: 5rem;
  margin-top: 0;

}

.arrows:hover,
.arrows:active {
  background-color: #f6f6f6;
}

@media only screen and (max-width: 600px) {
  .arrows:hover,
  .arrows:active {
    background-color: transparent;
  }

  .modal-content {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: none;
    border-radius: 5px;
  }
  .slider-pics {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19rem;
    height: 25rem;
  }
  .img-container{
    height: 80%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .img-container img{
    height: 100%;
    width: auto;
    
  }

  .img-container img {
    height: 100%;
    width: auto;
    max-width: 100%; 
    max-height: 100%; 
  }
}
.prices span{
  font-size: 1rem;
  background-color: #F8DC75;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  
}

.custom-next-arrow {
  right: 0; 
  z-index: 1;
}

.custom-prev-arrow {
  left: 0; 

  z-index: 1;
}


.close-modal {
  display: inline-flex;
  display: flex;
  justify-content: flex-end;

  background-color: #F0F04A;
  border-radius: 5px 5px 0 0;
}

.close-icon {
  cursor: pointer;
  height: 1.6rem;
}
.close-icon img {
  height: 100%;
}

.slick-slider, slick-slide, slick-list {
  border-radius: 5px;
}